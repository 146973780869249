import {Injectable} from '@angular/core';
import {DaoGeneric} from '@Services/base/daoService';
import {environment} from '@Env/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {CandidatureAter, StatusCandidateAter, StatusCandidateAterLabel} from '@Common/apps/ater/services/candidature/candidature.model';
import {FileAter} from "@Common/apps/ater/services/files/file.model";
import {OffreAter} from "@Common/apps/ater/services/offre/offre.model";

/**
 * Service pour le back-office
 */
@Injectable({
  providedIn: 'root'
})
export class CandidatureAterService extends DaoGeneric<CandidatureAter> {
  private url = `${environment.baseUrl}/ater/candidature/`;
  private readonly candidatureClassColors: string[] = [];
  constructor(private httpClient: HttpClient) {
    super(httpClient);
    this._initStatusColorClass();
  }

  getRootUrl(): string {
    return this.url;
  }

  exportAttachementsOffreCandidatures(offre: OffreAter) {
    const urlDownloadZipByOffre = `${this.url}/export_attachments_candidatures_by_offre/`;
    return this.httpClient.get(urlDownloadZipByOffre, {params: {offre_id: offre.id.toString()}, responseType: 'blob' });
  }

  exportAttachementsCandidatures(candidature: CandidatureAter) {
    const urlDownloadZip = `${this.url}${candidature.uuid}/export_attachments/`;
    let params = new HttpParams();
    return this.httpClient.get(urlDownloadZip, { params, responseType: 'blob' });
  }

  /**
   * Les classes se trouvent dans ater/styles.scss
   * @private
   */
  private _initStatusColorClass(): void {
    this.candidatureClassColors[StatusCandidateAter.draft] = 'dossier-draft';
    this.candidatureClassColors[StatusCandidateAter.admissible] = 'dossier-admissible';
    this.candidatureClassColors[StatusCandidateAter.inadmissible] = 'dossier-inadmissible';
    this.candidatureClassColors[StatusCandidateAter.send] = 'dossier-send';
    this.candidatureClassColors[StatusCandidateAter.incomplete] = 'dossier-incomplete';
  }
  getStateColorClass(state: StatusCandidateAter) {
    return this.candidatureClassColors[state] || 'dossier-waiting';
  }

  isCandidatureInvalid(candidature: CandidatureAter): boolean {
    const invalidStatuses = [StatusCandidateAter.inadmissible, StatusCandidateAter.incomplete];
    return invalidStatuses.includes(candidature.statut);
  }

  getInvalidFiles(candidature: CandidatureAter): FileAter[] {
    if (candidature.files_obj) {
      return candidature.files_obj.filter((file: FileAter) => file.valid === false);
    }
    return [];
  }

  /**
   * Les PJ qui ne sont ni au statut valid, ni au statut invalid
   * @param {CandidatureAter} candidature
   */
  getNoValidNoInvalidFiles(candidature: CandidatureAter): FileAter[]  {
    if (candidature.files_obj) {
      return candidature.files_obj.filter((file: FileAter) => file.valid === null);
    }
    return [];
  }

  /**
   * Retourne la liste des statuts selon la population
   * @param {string} population
   * @param {StatusCandidateAter[]} excludes : option, exclure certains statut de la liste
   */
  getListStatesLabels(population: 'gestionnaire' | 'candidat' = 'gestionnaire', excludes: StatusCandidateAter[] = []): StatusCandidateAterLabel[] {
    const _listStatus: StatusCandidateAterLabel[] = [];
    Object.keys(StatusCandidateAter).filter(key => {
      const _key = Number(key);
      if (! isNaN(_key) && ! excludes.includes(_key)) {
        const _label = population === 'gestionnaire' ?
          StatusCandidateAter.toStringGestionnaire(_key) :
          StatusCandidateAter.toStringCandidat(_key);
        _listStatus.push({id: _key, label: _label});
      }
    });
    return _listStatus;
  }

  /**
   * Export Candidatures
   */
  private _exportCandidaturesXls(extraParams: Map<string, string>) {
    const url = `${this.url}export_candidatures/`;
    const params = this._getUrlHttpParams(extraParams);
    return this.httpClient.get(url, { params, responseType: 'blob' });
  }

  public exportCampagneCandidatures(selectedCampagneId: number) {
    const extraParams: Map<string, string> = new Map<string, string>();
    // Si pas de campagne sélectionnée, on exporte toutes les candidatures
    if  (selectedCampagneId) {
      extraParams.set('offre__campagne', selectedCampagneId.toString());
    }
    return this._exportCandidaturesXls(extraParams);
  }
  public exportComiteCandidatures(offre: OffreAter) {
    const extraParams: Map<string, string> = new Map<string, string>();
    extraParams.set('offre', offre.id.toString());
    extraParams.set('statut', StatusCandidateAter.admissible.toString());
    return this._exportCandidaturesXls(extraParams);
  }

   /**
   * Retourne les candidatures
   * @param params
   */
  public getAllCandidatures(params: any) {
    const url = `${this.url}get_all_candidatures/`;
    return this.httpClient.get<CandidatureAter[]>(url, { params: params });
  }

  public deleteCandidatureAttachments(candidature: CandidatureAter): any {
    const url = `${this.url}deleteCandidatureAttachments/`;
    return this.httpClient.post<any>(url,candidature);
  }
}
