import { CandidatAter } from '../candidat/candidat.model';
import {Article} from "@Common/apps/ater/services/article/article.model";
import {FileAter} from "@Common/apps/ater/services/files/file.model";
import {OffreAter} from "@Common/apps/ater/services/offre/offre.model";
import {SeniorityEnum} from "@Common/apps/ater/services/seniority/seniority.model";


export enum StatusCandidateAter {
  draft = 1,
  send = 2,
  incomplete = 4,
  admissible = 5,
  inadmissible = 6,
}

export interface StatusCandidateAterLabel {
  id: StatusCandidateAter;
  label: string;
}

export namespace StatusCandidateAter {
  export const gestionnaireLabels = {
    [StatusCandidateAter.draft]: 'Brouillon',
    [StatusCandidateAter.send]: 'À traiter',
    [StatusCandidateAter.incomplete]: 'Incomplète',
    [StatusCandidateAter.admissible]: 'Recevable',
    [StatusCandidateAter.inadmissible]: 'Non recevable'
  };

  export const candidatLabels = {
    [StatusCandidateAter.draft]: 'Brouillon',
    [StatusCandidateAter.send]: 'Envoyée',
    [StatusCandidateAter.incomplete]: 'Incomplète',
    [StatusCandidateAter.admissible]: 'Recevable',
    [StatusCandidateAter.inadmissible]: 'Non recevable'
  };

  export function toStringGestionnaire(status: StatusCandidateAter) {
    return gestionnaireLabels[status];
  }

  export function toStringCandidat(status: StatusCandidateAter) {
    return candidatLabels[status];
  }
}

export interface CandidatureAter {
  id: number;
  uuid?: string;
  reference?: string;
  label?: string;
  article: number;
  article_obj?: Article;
  seniority: SeniorityEnum;
  statut: StatusCandidateAter;
  candidat: number;
  offre: number;
  candidat_obj: CandidatAter;
  offre_obj: OffreAter;
  files_obj?: FileAter[];
  content_type_candidature?: number; // son propre content_type
  candidat_full_name?: string;
  process_by?: string; // en traitement par gestionnaire
  motif?: string; // commentaire à destination du candidat
  motif_gestionnaire?: string;
  date_envoi?: string | Date;
  dt_created?: string | Date;
  dt_updated?: string | Date;
}
