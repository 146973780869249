import {Component, OnInit} from '@angular/core';
import {SubSink} from '@Common/core/utils/subsink';
import {Menu} from '@Common/home/models/menu.model';
import {IndividuService} from "@Common/core/services";
import {AccessibilityService} from "@Common/widgets/accessibility/services/accessibility.service";

@Component({
  selector: 'app-ater-candidatures-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'ATER candidatures';
  menu = [];
  showLoader = false;
  showAccessibility = true;
  subSink = new SubSink();

  constructor(private accessibilityService: AccessibilityService) {
  }

  ngOnInit(): void {
    this.accessibilityService.showAccessibilityInLogin(this.showAccessibility);
    this.menu = [
      <Menu> {url: '/historique', titre: 'Historique', nav: true, icon: 'history'},
    ]
  }
}
